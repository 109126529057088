import Logo from "./img/logo.png";
import "./sass/main.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { useState } from "react";
import Filosofia from "./components/filosofia/Filosofia";
import QuemSomos from "./components/quem-somos/QuemSomos";
import Equipe from "./components/equipe/Equipe";
import Servicos from "./components/servicos/Servicos";
import AreasAtuacao from "./components/areas-atuacao/AreasAtuacao";
import Contato from "./components/contato/Contato";
import Slider from "./components/slider/Slider";
import {
  Box,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";

function App() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupText, setPopupText] = useState("");

  const handleShowPopup = (text) => {
    console.log("Clicou!");
    setPopupText(text);
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    outline: "none",
    boxShadow: 24,
    borderRadius: '5px',
    p: 6,
  };

  const areas = [
    {
      id: 0,
      nome: "Direito Trabalhista",
      descricao:
        "O direito trabalhista é uma das principais áreas do direito que trata das relações de trabalho. A origem de suas normas está relacionada àquelas criadas pela Organização Internacional do Trabalho (OIT), além da cultura de um povo, as doutrinas, os regimentos das empresas e os contratos de trabalho. Nosso escritório atua na obtenção das melhores soluções trabalhistas aos nossos clientes, seja judicialmente em instância administrativa. Orientamos e auxiliamos em mesa redonda em Delegacias Regionais do Trabalho ou em negociações coletivas com sindicatos, bem como elaboramos defesas decorrentes de autos de infração lavrados pela Delegacia Regional do Trabalho.",
      url: "https://jro.adv.br/wp-content/uploads/2020/11/img-direito-trabalho-OK.jpg",
    },
    {
      id: 1,
      nome: "Direito Cível e Consumidor",
      descricao:
        "O Direito cível é um ramo do Direito que trata do conjunto de normas reguladoras dos direitos e obrigações de ordem privada concernente às pessoas, aos seus direitos e obrigações, aos bens e às suas relações, enquanto membros da sociedade. Temos como meta a resolução de problemas envolvendo todas as áreas do direito civil e consumerista, com atuação de profissionais especializados e focados nessa área do direito.",
      url: "https://advogadoscosta.com.br/wp-content/uploads/2023/06/martelo-de-juiz-e-carrinho-de-compras-em-um-laptop-conceito-de-sancoes.jpg",
    },
    {
      id: 2,
      nome: "Direito Previdenciário",
      descricao:
        "O direito previdenciário é um ramo do direito público originado da conquista dos direitos sociais no fim do século XIX e início do século XX. Seu objetivo é o estudo e a regulamentação do instituto seguridade social. Nosso objetivo é dar aos clientes as melhores soluções aos seus problemas sejam na esfera administrativa ou judicial. Orientamos e auxiliamos nossos clientes junto aos órgão públicos, para melhor solução de suas necessidades.",
      url: "https://www.galvaoesilva.com/_sys/uploads/2023/02/bank-manager-explaining-insurance-documents-his-senior-clients-during-consultations-office_637285-1183-transformed-1.webp",
    },
    {
      id: 3,
      nome: "Direito Tributário",
      descricao:
        "O Direito Tributário busca a orientação de compensação, patrocínio de pedido de restituição e análise de créditos tributários aos cientes, objetivando o resgate de todos os tributos indevidamente cobrados e pagos ao cofres públicos.",
      url: "https://thumbs.jusbr.com/imgs.jusbr.com/publications/images/d722ee96831fb2c20d2bce72608687b2",
    },
  ];

  const theme = createTheme({
    typography: {
      htmlFontSize: 10,
    },
  });

  return (
    <>
      <header className="header">
        <img className="header__logo" src={Logo} />

        <nav className="header__user-nav">
          <ul className="header__list">
            <a href="#quem-somos">
              <li className="header__list-item heading-6">Quem Somos</li>
            </a>
            <a href="#areas-atuacao">
              <li className="header__list-item heading-6">Áreas de Atuação</li>
            </a>
            <a href="#equipe">
              <li className="header__list-item heading-6">Equipe</li>
            </a>
            <a href="#contato">
              <li className="header__list-item heading-6">Contato</li>
            </a>
            <a href="#">
              <li className="header__list-item heading-6">Publicações</li>
            </a>
          </ul>
        </nav>
      </header>

      <Slider />

      <QuemSomos />

      <div className="parallax-background"></div>

      <Servicos areas={areas} />

      <Filosofia />

      <AreasAtuacao areas={areas} handleShowPopup={handleShowPopup} />

      <div className="parallax-background parallax-background--office"></div>

      <Equipe />

      <Contato />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.4956861380533!2d-46.6397399!3d-23.622412999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a573b510f9f%3A0x82fd7016ad4f2729!2sAv.%20Jabaquara%2C%202049%20-%20Mirand%C3%B3polis%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004045-003!5e0!3m2!1spt-PT!2sbr!4v1693941599098!5m2!1spt-PT!2sbr"
        width="100%"
        height="450"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        className="endereco"
      ></iframe>

      <footer className="footer">
        <p className="footer__paragraph">
          Copyright &copy; 2024 Magnoler & Iwashita - Todos os direitos
          reservados
        </p>
      </footer>

      <ThemeProvider theme={theme}>
        <Modal
          open={isPopupVisible}
          onClose={handleClosePopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {popupText}
            </Typography>
          </Box>
        </Modal>
      </ThemeProvider>
      {/*<Popup
        show={isPopupVisible}
        text={popupText}
        onClose={handleClosePopup}
  />*/}
    </>
  );
}

export default App;
