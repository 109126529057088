import React from 'react'

export default function AreasAtuacao({areas, handleShowPopup}) {
  return (
    <section className="areas-atuacao" id="areas-atuacao">
        <h3 className="heading-3 areas-atuacao__title">Áreas de Atuação</h3>

        <div className="areas-atuacao__box">
          <ul className="areas-atuacao__list">
            {areas.map((area) => {
              return (
                <li
                  onClick={() => handleShowPopup(area.descricao)}
                  key={area.id}
                  className="areas-atuacao__item"
                  style={{ backgroundImage: `url(${area.url})` }}
                >
                  <p>{area.nome}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
  )
}
