import { motion } from "framer-motion";

export default function Servicos({areas}) {
  const isMobile = window.innerWidth < 768;

  const titleAttributes = !isMobile
    ? {
        initial: { opacity: 0, x: "100" },
        transition: { duration: 1 },
        whileInView: { opacity: 1, x: 0 },
      }
    : "";

  const textAttributes = !isMobile
    ? {
        initial: { opacity: 0, x: "100" },
        transition: { duration: 2 },
        whileInView: { opacity: 1, x: 0 },
      }
    : "";

  const imgAttributes = !isMobile
    ? {
        initial: { opacity: 0, x: '-100' },
        transition: { duration: 1 },
        whileInView: { opacity: 1, x: 0 },
      }
    : "";

  return (
    <section className="servicos">
      <motion.div {...imgAttributes} className="servicos__img-box">
        {areas.map((area) => {
          return (
            <img
              key={area.id}
              className="servicos__img"
              src={area.url}
              alt={area.nome}
            />
          );
        })}
      </motion.div>
      <article className="servicos__box-text">
        <motion.h3 {...titleAttributes} className="heading-3 servicos__title">Serviços</motion.h3>
        <motion.p {...textAttributes} className="paragraph servicos__paragraph">
          Dentro do seu campo de atuação, o escritório de advocacia Magnoler &
          Iwashita Sociedade de Advogados está capacitado para atuar em diversas
          áreas do direito, sem perder o foco e com a filosofia de atuar um a
          um, com o objetivo de estabelecer uma relação de confiança com nossos
          clientes.
          <br />
          <br />O escritório concentra seu maior número de ações nas áreas do
          Direito do Trabalho, Cível, Previdenciário e Tributária, oferecendo
          assessoria jurídica e consultiva, objetivando a prevenção de litígios
          judiciais, além de advocacia contenciosa, defendendo os interesses de
          seus clientes em todos os fóruns e tribunais do país, com toda
          estrutura necessária para uma atuação e consultoria eficaz,
          objetivando a melhor solução aos clientes.
        </motion.p>
      </article>
    </section>
  );
}
