import Advogado1 from "../../img/advogado1.jpg";
import Advogado2 from "../../img/advogado2.jpg";
import Advogado3 from "../../img/advogado3.jpeg";

export default function Equipe() {
  return (
    <section className="equipe" id="equipe">
      <h3 className="heading-3 equipe__title">Nosso time</h3>

      <div className="equipe__list">
        <div className="equipe__list-box">
          <p>Marcos Santos</p>
          <img src={Advogado1} className="equipe__img" />
        </div>
        <div className="equipe__list-box">
          <p>Angela Marques</p>
          <img src={Advogado2} className="equipe__img" />
        </div>
        <div className="equipe__list-box">
          <p>Guilherme Silveira</p>
          <img src={Advogado3} className="equipe__img" />
        </div>
      </div>
    </section>
  );
}
