
import BG1 from "../../img/bg01.jpg";
import BG2 from "../../img/bg02.jpg";
import BG3 from "../../img/bg03.jpg";
import BG4 from "../../img/bg04.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Slider() {
  return (
    <section className="slider">
        <Swiper
          effect="fade"
          modules={[Autoplay, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop={true}
        >
          <SwiperSlide>
            <img src={BG3} alt="Background Image" className="slider__img" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={BG1} alt="Background Image" className="slider__img" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={BG2} alt="Background Image" className="slider__img" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={BG4} alt="Background Image" className="slider__img" />
          </SwiperSlide>
        </Swiper>

        <article className="slider__box-text">
          <h1 className="slider__title">
            MAGNOLER
            <br />& IWASHITA
          </h1>
          <p className="slider__subtitle">SOCIEDADE DE ADVOGADOS</p>
        </article>
      </section>
  )
}
