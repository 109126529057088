import {motion} from 'framer-motion';

export default function Filosofia() {
  const isMobile = window.innerWidth < 768;

  const titleAttributes = !isMobile
    ? {
        initial: { opacity: 0, y: "-100" },
        transition: { duration: 1 },
        whileInView: { opacity: 1, y: 0 },
      }
    : "";

  const textAttributes = !isMobile
    ? {
        initial: { opacity: 0, y: "-100" },
        transition: { duration: 2 },
        whileInView: { opacity: 1, y: 0 },
      }
    : "";
  return (
    <section className="filosofia">
        <article className="filosofia__box-text">
          <motion.h3 {...titleAttributes} className="heading-3 filosofia__title">Filosofia</motion.h3>
          <motion.p {...textAttributes} className="paragraph filosofia__paragraph">
            Nossa missão é oferecer extensa gama de serviços, buscando
            incansavelmente a excelência do trabalho produzido por seus
            profissionais, parceiros e correspondentes, através de modernas
            técnicas de gestão jurídica, que visam a conjugar o conhecimento
            técnico próprio da formação acadêmica, com a experiência e
            entendimento do ambiente comercial onde os seus clientes operam.<br/><br/>No
            exercício da advocacia e no relacionamento com seus clientes,
            Magnoler & Iwashita Sociedade de Advogados pauta o seu procedimento
            pela observância rigorosa dos princípios informadores e formadores
            da atividade do advogado.<br/><br/>Temos como meta a busca da melhor solução
            judicial ou não aos nossos clientes, através do conhecimento e
            aprimoramento constante de nossas técnicas de atuação.<br/><br/>Os trabalhos
            são sempre pautados pela total atenção aos princípios éticos e no
            atendimento sério, rápido e responsável, direcionando as atividades
            para o melhor assessoramento jurídico possível de seus clientes.
          </motion.p>
        </article>
      </section>
  )
}
