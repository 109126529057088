import { motion } from "framer-motion";
import Escritorio from "../../img/escritorio.jpg";

export default function QuemSomos() {
  const isMobile = window.innerWidth < 768;

  const titleAttributes = !isMobile
    ? {
        initial: { opacity: 0, x: "-50" },
        transition: { duration: 1 },
        whileInView: { opacity: 1, x: 0 },
      }
    : "";

  const textAttributes = !isMobile
    ? {
        initial: { opacity: 0, x: "-50" },
        transition: { duration: 2 },
        whileInView: { opacity: 1, x: 0 },
      }
    : "";

  const imgAttributes = !isMobile
    ? {
        initial: { opacity: 0, x: 100 },
        transition: { duration: 1 },
        whileInView: { opacity: 1, x: 0 },
      }
    : "";

  return (
    <section className="quem-somos" id="quem-somos">
      <article className="quem-somos__box-text">
        <motion.h3 {...titleAttributes} className="heading-3 quem-somos__title">
          O Escritório
        </motion.h3>
        <motion.p
          {...textAttributes}
          className="paragraph quem-somos__paragraph"
        >
          Magnoler & Iwashita Sociedade de Advogados é um escritório de
          advocacia composto de profissionais altamente qualificados para atuar
          em diversas áreas do direito, prestando serviços de assistência nas
          esferas administrativas e judiciais. Seu grande diferencial é
          conseguir unir conhecimento técnico com agilidade nas respostas aos
          clientes, com o intuito de atuar com maestria em todas as instâncias
          do judiciário.
          <br />
          <br />
          O escritório dispõe de uma estrutura moderna, situado na cidade de São
          Paulo, operando em instalações dotadas de total infraestrutura, com um
          eficiente sistema de comunicação e informática. A prioridade é dada ao
          atendimento personalizado aos clientes e a excelência dos serviços
          oferecidos, com soluções eficazes, de modo a atender as exigências
          atuais do mercado.
          <br />
          <br />
          Somos um escritório de advocacia com atuação em todo o país, contando
          com apoio de uma equipe de advogados experientes e especializados em
          diferentes áreas e setores do mercado.
          <br />
          <br />
          Com um trabalho dinâmico, criativo e exigente, Magnoler & Iwashita
          Sociedade de Advogados tem obtido uma presença marcante no ramo da
          advocacia com resultados louváveis, sempre com o objetivo de
          proporcionar um melhor atendimento e fidelidade aos seus clientes.
        </motion.p>
      </article>

      <motion.img {...imgAttributes} src={Escritorio} className="quem-somos__img" />
    </section>
  );
}
