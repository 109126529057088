import React from "react";

export default function Contato() {
  return (
    <section className="contato" id="contato">
      <h3 className="heading-3 equipe__title contato__title">Contato</h3>

      <div className="contato__box">
        <div className="contato__endereco">
          <p>
            Av. Jabaquara, 2049 - 7º andar
            <br />
            - cjs. 71, 73 e 75
            <br/>
            <br />
            Mirandópolis - São Paulo/SP
            <br />
            CEP 04045-003
          </p>
          <p>+55 (11) 2577-1473</p>
          <p>contato@misadvogados.com.br</p>
        </div>

        <form className="contato__form">
          <div className="contato__box-input">
            <label>Nome</label>
            <input type="text" placeholder="Seu nome" />
          </div>
          <div className="contato__box-input">
            <label>E-mail</label>
            <input type="email" placeholder="Seu e-mail" />
          </div>

          <div className="contato__box-input">
            <label>Mensagem</label>
            <textarea placeholder="Deixe aqui sua mensagem"></textarea>
          </div>

          <button className="contato__button">Enviar</button>
        </form>
      </div>
    </section>
  );
}
